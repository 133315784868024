import React from 'react';
import HTMLParser from 'html-react-parser';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import { formColor } from '../../../../../helper';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

class MilestonesLayout extends React.Component {
  constructor(props) {
    super(props);

    const dataIndex = props.section.data.findIndex((elem) => elem.type === 'DATA');

    const align = props.section.styles.align ? this.props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    const animationActive = dataIndex > -1 && props.section.data[dataIndex].animationActive;
    const animationDuration =
      dataIndex > -1 && props.section.data[dataIndex].animationDuration
        ? props.section.data[dataIndex].animationDuration
        : 4;

    let columns;
    if (props.section && props.section.styles && props.section.styles.columns) ({ columns } = props.section.styles);

    const animationStarted = [true, true];

    if (props.section && props.section.data) {
      props.section.data.map((data) => {
        if (data.type === 'COMPONENT') {
          animationStarted.push(false);
        }

        return null;
      });
    }

    this.state = {
      full,
      align,
      animationActive,
      animationDuration,
      animationStarted,
      columns,
    };

    this.animationStarted = this.animationStarted.bind(this);
  }

  animationStarted(isVisible, start, index) {
    if (isVisible && !this.state.animationStarted[index]) {
      start();

      this.setState((state) => ({
        animationStarted: state.animationStarted.map((val, i) => val || i === index),
      }));
    }
  }

  render() {
    let columns;
    if (this.state.columns === 1) columns = 'col-12';
    else columns = `col-12 col-md-6 col-lg-${this.state.columns ? 12 / this.state.columns : 3}`;

    const milestones = [];
    const top = [];
    this.props.section.data.map((item, index) => {
      if (item.type === 'COMPONENT' && item.active) {
        const elems = [];
        item.data.forEach((elem, i) => {
          if (elem.active && elem.type === 'IMAGES/IMAGE') {
            elems.push(
              <div key={`imageItem_${this.props.section._id}_${index}${i}`} className={styles.imageWrapper}>
                <CustomSectionMedia
                  variant={elem.content.icon ? 'fixedHeight' : undefined}
                  settings={elem.content.icon ? { fixedHeight: 148 } : undefined}
                  data={elem.content}
                  images={this.props.images}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                  filePathList={this.props.filePathList}
                  categoryPathList={this.props.categoryPathList}
                  sectionSlugs={this.props.sectionSlugs}
                  colors={this.props.themeData.colors}
                  sizes="(min-width: 992px) 25vw, (min-width: 768px) 50vw, 100vw"
                  loadingStrategy={this.props.isFirst ? 'eager' : undefined}
                />
              </div>,
            );
          } else if (elem.active && (elem.type.startsWith('HEADINGS/') || elem.type === 'PARAGRAPH/PARAGRAPH')) {
            const alignStyle = styles[`align${elem.align || item.align || this.state.align}`];
            let styleName;
            let style;
            let data;
            if (elem.type.startsWith('HEADINGS/')) {
              style = {
                fontWeight: this.props.themeData.typography.heading.weight,
                color: this.props.themeData.colors[3],
              };
              if (elem.type === 'HEADINGS/HEADING-TWO') {
                styleName = `${alignStyle} ${styles[`Title1${this.props.themeData.typography.heading.fontSize}`]}`;
                const rawData = elem.text.replace(/\D+/g, '');
                const value = parseInt(rawData, 10);
                data = this.state.animationActive ? (
                  <CountUp end={value} delay={0.2} useEasing duration={parseInt(this.state.animationDuration, 10)}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor
                        onChange={(isVisible) => this.animationStarted(isVisible, start, index)}
                        delayedCall
                      >
                        <span ref={countUpRef}> </span>
                      </VisibilitySensor>
                    )}
                  </CountUp>
                ) : (
                  <span>{value}</span>
                );
              } else {
                styleName = `${alignStyle} ${styles.milestonesSubtitle} ${
                  styles[`Subtitle2${this.props.themeData.typography.heading.fontSize}`]
                }`;
                data = HTMLParser(`<div>${elem.text}</div>`);
              }
            } else {
              styleName = `${alignStyle} ${styles.milestonesComponentParagraph} ${styles.exceptionLineHeight} ${
                styles[`Body${this.props.themeData.typography.default.fontSize}`]
              }`;
              data = HTMLParser(`<div>${elem.text}</div>`);
              style = { fontWeight: this.props.themeData.typography.default.weight };
            }

            const result = (
              <div key={`textItem_${this.props.section._id}_${index}${i}`} className={styleName} style={style}>
                {data}
              </div>
            );

            elems.push(result);
          }
        });

        let style;
        if (item.styles.backgroundColor.active) {
          let { backgroundColor } = item.styles;
          if (item.styles.backgroundColor.solid === '' && item.styles.backgroundColor.gradient.from === '')
            backgroundColor = { solid: '#ffffff' };

          style = formColor(
            backgroundColor,
            false,
            item.styles.backgroundColor.opacity,
            undefined,
            this.props.themeData.colors,
          );
        }

        if (style !== undefined) {
          style.paddingTop = '15px';
          style.paddingBottom = '15px';
        }

        const result = (
          <div
            key={`${this.props.section._id}_layout_${index}`}
            className={`${columns} ${styles.milestonesComponentWrapper} ${styles[`align${item.align}`]}`}
            style={style}
          >
            {elems}
          </div>
        );

        milestones.push(result);
      } else if (item.active && item.type.startsWith('HEADINGS/')) {
        let styleName;
        let data;
        if (item.type === 'HEADINGS/HEADING-TWO') {
          styleName = `col-12 ${styles[`align${item.align || this.state.align}`]} ${styles.milestonesMainHeader} ${
            styles[`Title2${this.props.themeData.typography.heading.fontSize}`]
          }`;
          data = `<h2>${item.text}</h2>`;
        } else {
          styleName = `col-12 ${styles[`align${item.align || this.state.align}`]} ${styles.milestonesSubHeader} ${
            styles[`Subtitle${this.props.themeData.typography.heading.fontSize}`]
          }`;
          data = `<h3>${item.text}</h3>`;
        }

        const text = (
          <div key={`${this.props.section._id}_elem_${index}`} className={styleName}>
            {HTMLParser(data)}
          </div>
        );

        top.push(text);
      }

      return null;
    });

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`milestonesContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          {top}
          {milestones}
        </div>
      </div>
    );
  }
}

export default MilestonesLayout;
